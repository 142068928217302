import React from "react";
import styled from "styled-components";
// Components
import PricingTable from "../Elements/PricingTable";

export default function Pricing() {
  return (
    <Wrapper id="pricing">
      <div className="whiteBg">
        <div className="container">
          <HeaderInfo>
            <h1 className="font40 extraBold">Start now & don't look back</h1>
            <p className="font18">
              We can help you protect your security.
            </p>
          </HeaderInfo>
          <TablesWrapper className="flexSpaceNull">
            <TableBox>
              <PricingTable
                icon="roller"
                price="$29,99/h"
                text=""
                title="Infrastructure Security Analyst"
                offers={[
                  { name: "External scanning", cheked: true },
                  { name: "Compliance reporting", cheked: true },
                  { name: "SSL / TLS certificate monitoring", cheked: false },
                  { name: "2,000+ open-source checks", cheked: false },
                  { name: "1 scheduled monthly scan", cheked: false },
                ]}
                action={() => alert("clicked")}
              />
            </TableBox>
            <TableBox>
              <PricingTable
                icon="monitor"
                price="$49,99/h"
                title="Cyber Security Engineer"
                text=""
                offers={[
                  { name: "11,000+ professionally curated checks", cheked: true },
                  { name: "External & internal scanning", cheked: true },
                  { name: "Unlimited scheduled scans", cheked: true },
                  { name: "Compliance reporting", cheked: true },
                  { name: "SSL / TLS certificate monitoring", cheked: true },
                  { name: "Automatic emerging threat scans", cheked: false },

                ]}
                action={() => alert("clicked")}
              />
            </TableBox>
            <TableBox>
              <PricingTable
                icon="browser"
                price="$99,99/h"
                title="Information Security Manager"
                text=""
                offers={[
                  { name: "11,000+ professionally curated checks", cheked: true },
                  { name: "External & internal scanning", cheked: true },
                  { name: "Unlimited ad hoc scans", cheked: true },
                  { name: "Unlimited scheduled scans", cheked: true },
                  { name: "Automatic emerging threat scans", cheked: true },
                  { name: "SSL / TLS certificate monitoring", cheked: true },
                  { name: "Compliance reporting", cheked: true },
                  { name: "Network view", cheked: true },
                ]}
                action={() => alert("clicked")}
              />
            </TableBox>
          </TablesWrapper>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  width: 100%;
  padding: 20px 0;
`;
const HeaderInfo = styled.div`
  margin-bottom: 20px;
  @media (max-width: 860px) {
    text-align: center;
  }
`;
const TablesWrapper = styled.div`
  @media (max-width: 860px) {
    flex-direction: column;
  }
`;
const TableBox = styled.div`
  width: 31%;
  @media (max-width: 860px) {
    width: 100%;
    max-width: 370px;
    margin: 0 auto
  }
`;




